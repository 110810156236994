import api from "@/api/api.js";
import sessionsApi from "../common/api/sessionsAxios";
import AxiosConnect from "@/common/api/AxiosConnect";
import router from "@/router";
import { socket } from "@/socket";
import { appLogout } from "@/js/applications.js";

const initValuesToMagnament = {
  role: "model",
  userIdOtherCollection: "",
  documentType: "idcard",
  documentId: "",
  name: "",
  lastname: "",
  email: "",
  phone: "",
  artisticName: "",
  qualification: "",
  entrydate: "",
  monitorId: "",
  city: "",
  office: "",
  room: "",
  workShiftName: "",
  area: "",
  roles: [],
  rolesApp: {},
  picture: "",
  pictureUrl: null,
  fileName: "",
  user: "",
  password: "",
  repeatPassword: "",
  jasminUser: "",
  jasminPassword: "",
  streamateUser: "",
  streamatePassword: "",
  polices: {},
  companies: [],
};

const { qualification, entrydate, monitorId, jasminUser, jasminPasswordroom, streamateUser, streamatePassword, room, ...otherUsers } = initValuesToMagnament;

export default {
  namespaced: true,
  state: () => ({
    accountant: +localStorage.getItem("acount") || 0,
    activeNotification: false,
    goalDate: "",
    role: null,
    area: null,
    isSpecialFunctions: null,
    user: null,
    profile: {},
    email: null,
    token: localStorage.getItem(process.env.VUE_APP_TOKEN_NAME) || null,
    error: "",
    sstk: "",
    msgVerifyRoom: "",
    usersToAssign: {},
    ModelFinish: {},
    menuAssign: {},
    userProfile: {},
    adminPermissions: false,
    secNextReserv: 0,
    showAlertNextReserv: true,
    userInWindow: true,
    userSelected: {},
    companyAreas: [],
    userList: [],
    toShowInMagnament: 0,
    dataUser: { ...initValuesToMagnament },
    numTest: 1,
    needPasswordChange: false,
    localIPs: [],
    requiredValues: {
      TypeUser: ["role"],
      PersonalData: ["documentType", "documentId", "name", "lastname", "email", "phone", "userIdOtherCollection"],
      InformationRoleModel: ["artisticName", "qualification"],
      UserLocation: ["city", "office", "room", "workShiftName", "area", "companies"],
      ApplicationsUser: ["roles"],
      UserCredentials: ["user", "password", "repeatPassword"],
      UserPlatforms: [],
      UserPolicies: [],
    },
    orderUsersByArea: [],
    is2FALogged: false,
    optional2FALoginAreas: [],
    user2FASecret: null,
    queryUser: null,
    userAuthMethods: [],
    userId: null,
  }),
  actions: {
    async orderUsersAreas({ commit }) {
      try {
        const { data } = await this.$axios.get(`/usersbyarea`);
        const { users, error } = data;
        if (error) {
          throw new Error(error);
        }
        commit("setOrderUsersList", users);
      } catch (e) {
        router.push(`/error?msg=${e?.response?.statusText || e}`);
        return false;
      }
    },
    async startApp() {
      try {
        const resp = await api.get("/");
        return true;
      } catch (e) {
        router.push(`/error?msg=${e?.response?.statusText || e}`);
        return false;
      }
    },
    async startAuthUser({ dispatch }) {
      try {
        const token = localStorage.getItem(process.env.VUE_APP_TOKEN_NAME);

        if (!token) {
          return;
        }

        await dispatch("fetchProfile");
      } catch (error) {
        console.log(error);
      }
    },
    isLoggedIn() {
      return !!localStorage.getItem(process.env.VUE_APP_TOKEN_NAME);
    },

    logoutClient({ dispatch }, user) {
      dispatch("logoutResetData");
      dispatch("logOutSocket");
      appLogout(user);
    },
    async logoutMethod({ dispatch }, payload = {}) {
      const user = payload;

      await sessionsApi.post(`/auth/logout`, { SSID: user.SSID, _id: user._id });
      dispatch("logoutClient", user);
      this.$router.push("/login");
    },
    logoutResetData({ commit, state }) {
      localStorage.removeItem(process.env.VUE_APP_TOKEN_NAME);
      localStorage.removeItem("acount");
      localStorage.removeItem("ipAllowed");
      localStorage.removeItem("getModels");
      localStorage.removeItem("validateEntry");
      localStorage.removeItem("nextReservHour");
      localStorage.removeItem("user2FASecret");
      localStorage.removeItem("is2FAEnabled");
      localStorage.removeItem("2FALogin");
      state.token = null;
      state.accountant = 0;
      commit("setToken", null);
      commit("setIs2FALogged", false);
    },
    logOutSocket() {
      if (socket) {
        socket.disconnect();
        socket.close();
        socket.removeAllListeners();
      }
    },
    async loginMethod({ dispatch, commit, state }, payload) {
      const { app } = payload;
      const userToSend = payload?.user || {};
      const newLocalIPs = await dispatch("getLocalIps");

      userToSend.localIPs = newLocalIPs.length ? newLocalIPs : state.localIPs;

      try {
        const {
          data: { token = "", user = {}, details = null, error = null },
        } = await sessionsApi.post(`/auth`, userToSend);

        const { user2FASecret } = user;
        commit("setUser2FASecret", user2FASecret);

        if (error) {
          commit("setError", details);
        }

        let rolesForceChangePassword = ["employee", "super", "coord", "model", "monitor"];

        if (user && !user.lastPasswordChange && user.roles && rolesForceChangePassword.includes(user.roles[0])) {
          state.needPasswordChange = user;
          router.push("/needchangepassword");
          return;
        }
        if (!user?.role) {
          return false;
        } else {
          dispatch("setLogin", { user, token });
          return { token, details, error, app, user };
        }
      } catch (error) {
        console.log("error :>> ", error);
        return false;
      }
    },
    async getLocalIps() {
      try {
        const dataAxios = { baseURL: process.env.VUE_APP_AUTLOCAL_API };
        const { data } = await AxiosConnect.instance(dataAxios).get("/address");
        const { ips } = data;

        const listIps = ips && ips.length ? ips : [];
        return listIps;
      } catch (error) {
        console.log(error.message);
        return [];
      }
    },
    setLogin({ commit }, { user, token }) {
      commit("setToken", token);
      commit("setRole", user.role);
      commit("setArea", user.area);
      commit("setSpecialFunctions", user.isSpecialFunctions);
      commit("setProfile", user);
      commit("setError", "");
    },
    readTkn({ commit }) {
      if (localStorage.getItem(process.env.VUE_APP_TOKEN_NAME)) {
        commit("setToken", localStorage.getItem(process.env.VUE_APP_TOKEN_NAME));
      } else {
        commit("setToken", null);
      }
    },
    async fetchProfile({ commit }) {
      const { data } = await sessionsApi.get(`/session/validsession`);
      commit("setProfile", data.user);
      return data.user;
    },
    async validateRoomConnection({ state, dispatch }) {
      let msgVerifyRoom;
      try {
        const { ips = [] } = state.profile;

        if (!ips.length && process.env.VUE_APP_MODE === "prod") {
          throw new Error("Conexión externa.");
        }

        const { data } = await this.$axios.post(`/operation/verifyRoomConnection`);
        const { error, success, message, reservToUpd } = data;

        if (error) {
          throw new Error(error);
        }
        if (!success) {
          await dispatch("showMessageRoom", { message, reservToUpd });
        }

        msgVerifyRoom = message;
      } catch (error) {
        msgVerifyRoom = error.message;
      } finally {
        state.msgVerifyRoom = msgVerifyRoom;
      }
    },
    async showMessageRoom({ state }, { message, reservToUpd }) {
      if (!reservToUpd) {
        this.$alerts.dataSendError({ title: "Error", message });
        return;
      }

      const { isConfirmed } = await this.$alerts.dataSendConfirm({
        title: "¿Quieres actualizar tu reserva a esta habitación?",
        message,
        confirmButtonText: "Confirmar",
        cancelButtonText: "Cancelar",
        showCancelButton: true,
      });

      if (isConfirmed) {
        const { data } = await this.$axios.post(`/operation/updRoomReser?reserId=${reservToUpd}`);
        const { success, message } = data;

        if (!success) {
          this.$alerts.dataSendError({ title: "Error", message });
          return;
        }

        state.msgVerifyRoom = message;
      }
    },
    couterNextReserv({ dispatch, commit }, hour) {
      localStorage.setItem("nextReservHour", hour);
      commit("changeAlertNextReser", true);
      dispatch("updateNextReserHour");
    },
    updateNextReserHour({ dispatch, commit }) {
      const nextReservHour = localStorage.getItem("nextReservHour");

      if (!nextReservHour) {
        return;
      }

      const currentDate = new Date();
      const hourToNextReserv = new Date(nextReservHour);
      const diff = hourToNextReserv.getTime() - currentDate.getTime();

      if (diff < 0) {
        commit("udpSecNextReserv", 0);
        localStorage.removeItem("nextReservHour");
        dispatch("validateRoomConnection");
        return;
      }

      commit("udpSecNextReserv", diff);
      setTimeout(() => dispatch("updateNextReserHour"), 1000);
    },
    async validateAssignmet({ state, rootGetters, dispatch }) {
      try {
        const modelsInRoom = rootGetters["connectedUsers/getModelsInRoom"] || [];
        const myUser = modelsInRoom.find(({ _id }) => state.profile._id === _id) || {};

        if (myUser?.monitorIdTemp) {
          return;
        }

        const { data } = await this.$axios.post(`/operation/wantToBroadcast`);
        const { error } = data;

        if (error) {
          throw new Error(error.message);
        }
      } catch (error) {
        console.log(error);
      }

      setTimeout(() => dispatch("validateAssignmet"), 1800000);
    },
    changeRoleInDataUser({ state }, role) {
      state.dataUser = structuredClone(role === "model" ? initValuesToMagnament : otherUsers);
      state.dataUser.role = role;

      if (!["model", "monitor"].includes(role)) {
        delete state.dataUser.workShiftName;
        delete state.dataUser.office;
      }
    },
    async createUser({ state }, deleteExtraFixed) {
      try {
        const formData = new FormData();
        const body = structuredClone(state.dataUser);
        body.deleteExtraFixed = deleteExtraFixed;

        delete body.fileName;
        delete body.pictureUrl;

        Object.entries(body).forEach(([key, value]) => {
          const formatValue = typeof value !== "string" && key !== "picture" ? JSON.stringify(value) : value;

          formData.append(key, formatValue);
        });

        const { data } = await this.$axios.post("/users", formData);
        const { newUser } = data;

        if (newUser) {
          state.userList.push(newUser);
        }
        return data;
      } catch (error) {
        console.log("error :>> ", error);
      }
    },
    async updateUser({ commit }, body) {
      const formData = new FormData();
      const { _id, ...updater } = body;

      delete updater.fileName;
      delete updater.pictureUrl;

      Object.entries(updater).forEach(([key, value]) => {
        const formatValue = typeof value !== "string" && key !== "picture" ? JSON.stringify(value) : value;

        formData.append(key, formatValue);
      });

      const { data } = await this.$axios.put(`/users/${_id}`, formData);
      const { userEdited } = data;
      if (userEdited) {
        commit("updateUser", userEdited);
      }
      return data;
    },
    setInfoToEdit({ state, dispatch, commit }) {
      const userSelected = structuredClone(state.userSelected);
      dispatch("changeRoleInDataUser", userSelected.role);

      const dataUser = state.dataUser;

      commit("updateKeyToDataUser", { key: "repeatPassword", value: "" });
      commit("updateKeyToDataUser", { key: "password", value: "" });
      commit("updateKeyToDataUser", { key: "_id", value: "" });

      Object.entries(dataUser).forEach(([key, val]) => {
        const value = userSelected[key] ?? val;

        if (key === "workShiftName") {
          commit("updateKeyToDataUser", { key, value: userSelected?.workShift?.name || "" });
          return;
        }
        if (key === "qualification") {
          const qualification = value === "NaN" ? "" : value;
          commit("updateKeyToDataUser", { key, value: qualification });
          return;
        }
        if (key === "picture" || key === "pictureUrl") {
          commit("updateKeyToDataUser", { key: "pictureUrl", value: userSelected["picture"] ?? "" });
          return;
        }
        if (key === "entrydate") {
          const entrydate = userSelected[key] ?? val;
          const formatDate = entrydate.split("T")[0];
          commit("updateKeyToDataUser", { key, value: formatDate });
          return;
        }
        if (key === "fileName") {
          const picture = userSelected["picture"] ?? "";
          const pictureNameParts = picture.split("/");
          const pictureName = pictureNameParts[pictureNameParts.length - 1];
          commit("updateKeyToDataUser", { key: "fileName", value: pictureName });
          return;
        }

        commit("updateKeyToDataUser", { key, value });
      });
    },
    async getUsersList({ commit }, query = "") {
      try {
        const { data } = await this.$axios.get(`/userslist?${query}`);
        const { users, error } = data;

        if (error) {
          throw new Error(error);
        }

        commit("setUserList", users);
      } catch (error) {
        console.log(error);
      }
    },
    async get2FAOptionalAreas({ commit, state }) {
      const {
        data: { areas },
      } = await sessionsApi.get("/areas");
      commit("setOptional2FALoginAreas", areas);
    },

    async getAuthMethods({ commit, state }) {
      const {
        data: { error, _id, userAuthMethods },
      } = await sessionsApi(`/user/?searchCriteria=${state.queryUser}`);

      if (!error) {
        commit("setAuthMethods", userAuthMethods);
        commit("setUserId", _id);
      } else {
        commit("setAuthMethods", []);
        commit("setUserId", null);
      }
    },
  },
  mutations: {
    setOrderUsersList(state, payload) {
      state.orderUsersByArea = payload;
    },
    setUserList(state, payload) {
      state.userList = payload;
    },
    updateUser(state, payload) {
      const userIdx = state.userList.findIndex((user) => user._id === payload._id);

      if (userIdx !== -1) {
        state.userList.splice(userIdx, 1, payload);
      } else {
        state.userList.push(payload);
      }

      if (state.userSelected._id === payload._id) {
        Object.entries(payload).forEach(([key, value]) => (state.userSelected[key] = value));
      }
      if (state.userSelected.hasOwnProperty("license") && !payload.hasOwnProperty("license")) {
        delete state.userSelected.license;
      }
    },
    updateKeyToDataUser(state, payload) {
      const { key, value } = payload;
      state.dataUser[key] = value;
    },
    updateUserInWindow(state, payload) {
      state.userInWindow = payload;
    },
    udpSecNextReserv(state, value) {
      state.secNextReserv = value;
    },
    changeAlertNextReser(state, value) {
      state.showAlertNextReserv = value;
    },
    setError(state, msg) {
      state.error = msg;
    },
    setRole(state, value) {
      state.role = value;
    },
    setArea(state, value) {
      state.area = value;
    },
    setSpecialFunctions(state, value) {
      state.isSpecialFunctions = value;
    },
    setToken(state, payload) {
      state.token = payload;
    },
    setProfile(state, profile) {
      state.profile = profile;
      state.user = profile;
    },
    setIs2FALogged(state, status) {
      state.is2FALogged = status;
    },
    setOptional2FALoginAreas(state, areas) {
      state.optional2FALoginAreas = areas;
    },
    setUser2FASecret(state, secret) {
      state.user2FASecret = secret;
    },
    setQueryUser(state, user) {
      state.queryUser = user;
    },
    setAuthMethods(state, authMethods) {
      state.userAuthMethods = authMethods;
    },
    setUserId(state, _id) {
      state.userId = _id;
    },
  },
  getters: {
    isAuthenticated: (state) => !!state.token,
    authStatus: (state) => state.status,
    getRole: (state) => {
      if (state.role !== null) return state.role;
      if (state.token !== null) return state.profile.role;
      return null;
    },
    getUserArea(state) {
      return state.area;
    },
    getSpecialFunctions: (state) => {
      if (state.isSpecialFunctions !== null) return state.isSpecialFunctions;
      if (state.token !== null) return state.profile.isSpecialFunctions;
      return null;
    },
    getEmail: (state) => {
      if (state.token !== null) return state.profile.email;
      return null;
    },
    getId: (state) => {
      if (state.token !== null) return state.profile._id;
      return null;
    },
    getProfile: (state) => {
      if (state.profile !== null) {
        return state.profile;
      }
      return {};
    },
    getProfileToken: (state) => {
      if (state.token !== null) {
        return state.profile;
      }
      return null;
    },
    getOrderUsersByArea: (state) => {
      return state.orderUsersByArea;
    },
    getIs2FALogged(state) {
      return state.is2FALogged;
    },
    getOptional2FALoginAreas(state) {
      return state.optional2FALoginAreas;
    },
    getIsRequired2FA(state) {
      const isAreaRequired = state.optional2FALoginAreas.findIndex((area) => area._id === state.area && area.twoFactorAuth !== "optional") !== -1;
      return isAreaRequired;
    },
    getToken(state) {
      return state.token;
    },
    getUser2FASecret(state) {
      return state.user2FASecret;
    },

    getUserAuthMethods(state) {
      return state.userAuthMethods;
    },
    getQueryUser(state) {
      return state.queryUser;
    },
    getUserId(state) {
      return state.userId;
    },
    userCanUseBot(state) {
      const { role } = state.profile || {};
      const availableRoles = ["monitor", "model"];
      const availableEnvs = ["local", "dev", "qa"];
      
      return availableEnvs.includes(process.env.VUE_APP_MODE) || availableRoles.includes(role);
    },
  },
};
